import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Footer, Header } from "./components";
import { Main, ContactUs } from "./pages";

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Route
        render={() => (
          <Switch>
            <Route
              path="/"
              key="/"
              exact
              render={() => {
                return (
                  <React.Suspense fallback={null}>
                    <Main />
                  </React.Suspense>
                );
              }}
            />
            <Route
              path="/contactus"
              key="/contactus"
              exact
              render={() => {
                return (
                  <React.Suspense fallback={null}>
                    <ContactUs />
                  </React.Suspense>
                );
              }}
            />
          </Switch>
        )}
      />
      <Footer />
      <a id="back-to-top" href="#" className="show">
        <i className="flaticon-arrow-pointing-to-up"></i>
      </a>
    </BrowserRouter>
  );
};

export default Router;
