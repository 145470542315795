import { Link } from "react-router-dom";

import bg1_box from "../assets/images/bg1-box.png";
import bg2_box from "../assets/images/bg2-box.png";
import bg1_box2 from "../assets/images/bg1-box2.png";
import bg2_box2 from "../assets/images/bg2-box2.png";
import bg1_box3 from "../assets/images/bg1-box3.png";
import bg2_box3 from "../assets/images/bg2-box3.png";

const Service = () => {
  return (
    <section className="hone7-service">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="ot-heading text-center m-b40">
              <h6>
                <span>LAS PYMES ELIGEN A</span>
              </h6>
              <h2 className="main-heading">
                SAP <br /> Business One
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div className="icon-box s3 box-hover-gradient text-center">
              <div className="bg-s3"></div>
              <div
                className="bg-before"
                style={{
                  background: `url(${bg1_box}) top left no-repeat #fff`,
                }}
              ></div>
              <div
                className="bg-after"
                style={{
                  background: `url(${bg2_box}) bottom right no-repeat #fff`,
                }}
              ></div>
              <div className="icon-main">
                <span className="flaticon-startup"></span>
              </div>
              <div className="content-box">
                <h5>Accesible</h5>
                <p>Ofrece un bajo costo total de propiedad.</p>
              </div>
              <div className="action-box">
                <Link
                  to="/contactus"
                  className="octf-btn octf-btn-icon octf-btn-white"
                >
                  Contáctenos<i className="flaticon-right-arrow-1"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 m-t50">
            <div className="icon-box s3 box-hover-gradient text-center">
              <div className="bg-s3"></div>
              <div
                className="bg-before"
                style={{
                  background: `url(${bg1_box2}) top left no-repeat #fff`,
                }}
              ></div>
              <div
                className="bg-after"
                style={{
                  background: `url(${bg2_box2}) bottom right no-repeat #fff`,
                }}
              ></div>
              <div className="icon-main">
                <span className="flaticon-system"></span>
              </div>
              <div className="content-box">
                <h5>Exhaustivo</h5>
                <p>
                  Todas tus necesidades departamentales se satisfacen con una
                  sola solución.
                </p>
              </div>
              <div className="action-box">
                <Link
                  to="/contactus"
                  className="octf-btn octf-btn-icon octf-btn-white"
                >
                  Contáctenos<i className="flaticon-right-arrow-1"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 xs-m-t50">
            <div className="icon-box s3 box-hover-gradient text-center">
              <div className="bg-s3"></div>
              <div
                className="bg-before"
                style={{
                  background: `url(${bg1_box3}) top left no-repeat #fff`,
                }}
              ></div>
              <div
                className="bg-after"
                style={{
                  background: `url(${bg2_box3}) top left no-repeat #fff`,
                }}
              ></div>
              <div className="icon-main">
                <span className="flaticon-internet"></span>
              </div>
              <div className="content-box">
                <h5>Rápido y Fácil</h5>
                <p>
                  Puedes estar en funcionamiento en cuestión de días o semanas.
                </p>
              </div>
              <div className="action-box">
                <Link
                  to="/contactus"
                  className="octf-btn octf-btn-icon octf-btn-white"
                >
                  Contáctenos<i className="flaticon-right-arrow-1"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 m-t50">
            <div className="icon-box s3 box-hover-gradient text-center">
              <div className="bg-s3"></div>
              <div
                className="bg-before"
                style={{
                  background: `url(${bg1_box2}) top left no-repeat #fff`,
                }}
              ></div>
              <div className="bg-after"></div>
              <div className="icon-main">
                <span className="flaticon-report"></span>
              </div>
              <div className="content-box">
                <h5>Poderoso</h5>
                <p>Todo lo que necesitas ahora al alcance de tu mano.</p>
              </div>
              <div className="action-box">
                <Link
                  to="/contactus"
                  className="octf-btn octf-btn-icon octf-btn-white"
                >
                  Contáctenos<i className="flaticon-right-arrow-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
