import banner from "../assets/images/banner-home7.png";

const Home = () => {
  return (
    <section
      className="home7-top particles-js"
      data-color="#fe4c1c,#00c3ff,#0160e7"
      data-id="i7"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xs-12 text-center">
            <div className="home7-top-block">
              <h6>Pioneros en SAP Business One</h6>
              <h2>
                Somos tus aliados en crecimiento, enfoque y administración.{" "}
                <br />
              </h2>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 text-center">
            <div className="home7-top-img">
              <img src={banner} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
