import logo_shape from "../assets/images/logo-shape.png";

const Particle = () => {
  return (
    <section
      className="home7-process particles-js"
      data-color="#fe4c1c,#00c3ff,#0160e7"
      data-id="i7-1"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 text-center m-b35">
            <div className="ot-heading">
              <h6>
                <span>
                  Deseamos para tu compañía lo que tenemos en la nuestra
                </span>
              </h6>
              <h2 className="main-heading">
                Velocidad, Flexibilidad e Innovación son conceptos
                <br />
                que usamos todos los días para lograr nuestros objetivos.
              </h2>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 text-center">
            <div className="ot-cprocess">
              <div className="ot-cprocess-item clearfix repeater-item-1 text-right">
                <div className="ot-cprocess-item-number font-second">01</div>
                <div className="ot-cprocess-item-inner">
                  <div className="ot-cprocess-item-dot"></div>
                  <div className="ot-cprocess-item-title font-second">
                    Innovación y Liderazgo
                  </div>
                  <div className="ot-cprocess-item-desc font-main">
                    <p>
                      Combinar estos dos conceptos es la clave para lograr
                      nuestros objetivos. Todo proyecto requiere de aplicar de
                      personas con liderazgo que sepan aplicar el conocimiento
                      de un forma innovadora.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ot-cprocess-item clearfix repeater-item-2 text-center">
                <div className="ot-cprocess-item-number font-second">02</div>
                <div className="ot-cprocess-item-inner">
                  <div className="ot-cprocess-item-dot"></div>
                  <div className="ot-cprocess-item-title font-second">
                    Acompañamiento total
                  </div>
                  <div className="ot-cprocess-item-desc font-main">
                    <p>
                      ¿Lograr la transformación digital en tu empresa es la
                      meta?
                      <br />
                      La respuesta es Sí.
                      <br />
                      Entonces, te acompañamos en este proceso porque conocemos
                      el camino y los atajos para llegar ahí.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ot-cprocess-item clearfix repeater-item-3 text-left">
                <div className="ot-cprocess-item-number font-second">03</div>
                <div className="ot-cprocess-item-inner">
                  <div className="ot-cprocess-item-dot"></div>
                  <div className="ot-cprocess-item-title font-second">
                    Garantía de servicio
                  </div>
                  <div className="ot-cprocess-item-desc font-main">
                    <p>
                      Sabemos que requiere tu compañía para una operación veloz,
                      flexible y que ayude a los objetivos, tanto del largo
                      plazo como del día a día: Servicio Garantizado. Cuenta con
                      ello.
                    </p>
                  </div>
                </div>
              </div>
              <div className="circle_logo">
                <img src={logo_shape} alt="" />
              </div>
              <svg
                className="ot-cprocess-circle-chart"
                height="500"
                width="500"
              >
                <circle
                  cx="250"
                  cy="250"
                  r="225"
                  stroke="url(#gradient)"
                  stroke-width="50"
                  fill="none"
                ></circle>
                <linearGradient id="gradient" gradientTransform="rotate(45)">
                  <stop offset="0%" stop-color="#015be6"></stop>
                  <stop offset="100%" stop-color="#03baee"></stop>
                </linearGradient>
              </svg>
            </div>
            <div className="services-content-marketing">
              <div className="process-box">
                <div className="number-box">01</div>
                <div className="icon-main"></div>
                <div className="content-box">
                  <h5>Innovación y Liderazgo</h5>
                  <p>
                    Combinar estos dos conceptos es la clave para lograr
                    nuestros objetivos. Todo proyecto requiere de aplicar de
                    personas con liderazgo que sepan aplicar el conocimiento de
                    un forma innovadora.
                  </p>
                </div>
              </div>
              <div className="process-box">
                <div className="number-box">02</div>
                <div className="icon-main"></div>
                <div className="content-box">
                  <h5>Acompañamiento total</h5>
                  <p>
                    ¿Lograr la transformación digital en tu empresa es la meta?
                    <br />
                    La respuesta es Sí.
                    <br />
                    Entonces, te acompañamos en este proceso porque conocemos el
                    camino y los atajos para llegar ahí.
                  </p>
                </div>
              </div>
              <div className="process-box">
                <div className="number-box">03</div>
                <div className="icon-main"></div>
                <div className="content-box">
                  <h5>Garantía de servicio</h5>
                  <p>
                    Sabemos que requiere tu compañía para una operación veloz,
                    flexible y que ayude a los objetivos, tanto del largo plazo
                    como del día a día: Servicio Garantizado. Cuenta con ello.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Particle;
