import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";

import "./assets/css/bootstrap.css";
import "./assets/css/font-awesome.css";
import "./assets/css/flaticon.css";
import "./assets/css/slick.css";
import "./assets/css/slick-theme.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/royal-preload.css";
import "./assets/css/style.css";


ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById("root")
);
