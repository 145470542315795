import { Link } from "react-router-dom";
import bg_page_header from "../assets/images/bg-page-header.jpg";

const ContactUs = () => {
  return (
    <div id="content" className="site-content">
      <div
        className="page-header dtable text-center"
        style={{ backgroundImage: `url(${bg_page_header})` }}
      >
        <div className="dcell">
          <div className="container">
            <h1 className="page-title">Contáctenos</h1>
            <ul id="breadcrumbs" className="breadcrumbs">
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li className="active">Contáctenos</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="p-t110 z-index-1 section-contact">
        <div className="container">
          <div className="row flex-row">
            <div className="col-md-6 col-xs-12 sm-m-b60">
              <div className="our-contact text-light">
                <div className="ot-heading text-light">
                  <h6>
                    <span>Detalles de Contacto</span>
                  </h6>
                  <h2 className="main-heading">Nuestros Contactos </h2>
                </div>
                <p className="m-b45">
                  ¿Estás buscando asegurar la competitividad de tu empresa
                  mediante el uso de tecnología y además impulsar la
                  productividad de tu equipo de trabajo? Entonces, estás en el
                  lugar correcto.
                </p>
                <div className="contact-info text-light m-b30">
                  <i className="flaticon-world"></i>
                  <div className="info-text">
                    <h6>Nuestra dirección:</h6>
                    <p>Heriberto Covarrubias 21, 604, Ñuñoa</p>
                  </div>
                </div>
                <div className="contact-info text-light m-b30">
                  <i className="flaticon-note"></i>
                  <div className="info-text">
                    <h6>Nuestro mailbox:</h6>
                    <p>
                      <a href="mailto:contacto@siriusconsulting.cl">
                        contacto@siriusconsulting.cl
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-info text-light">
                  <i className="flaticon-viber"></i>
                  <div className="info-text">
                    <h6>Nuestro teléfono:</h6>
                    <p>
                      <a href="tel:+56 22773435">+56 22773435</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12 align-self-center">
              <div className="form-contact">
                <div className="ot-heading">
                  <h6>
                    <span>Desea más información</span>
                  </h6>
                  <h2 className="main-heading">Nos gustaría saber de ti</h2>
                </div>
                <form
                  action="http://templates.thememodern.com/onum/contact.php"
                  className="wpcf7-form"
                  method="POST"
                >
                  <p>
                    <span className="wpcf7-form-control-wrap your-name">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="wpcf7-form-control wpcf7-text"
                        placeholder="Su Nombre *"
                        required=""
                      />
                    </span>
                  </p>
                  <p>
                    <span className="wpcf7-form-control-wrap your-email">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="wpcf7-form-control wpcf7-text wpcf7-email"
                        placeholder="Su Email *"
                        required=""
                      />
                    </span>
                  </p>
                  <p>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        className="wpcf7-form-control wpcf7-textarea"
                        placeholder="Mensaje..."
                        required=""
                      ></textarea>
                    </span>
                  </p>
                  <p>
                    <button
                      type="submit"
                      className="octf-btn octf-btn-primary octf-btn-icon"
                    >
                      Enviar Mensaje <i className="flaticon-right-arrow-1"></i>
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
