import image2_home7 from "../assets/images/image2-home7.png";
const ParticleFeature = () => {
  return (
    <section
      className="home7-feature particles-js"
      data-color="#fe4c1c,#00c3ff,#0160e7"
      data-id="i7-2"
    >
      <div className="container">
        <div className="flex-row">
          <div className="home7-feature-col1">
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">01</span>
              <div className="icon-main number-box">
                <span className="flaticon-pie-chart-1"></span>
              </div>
              <div className="content-box">
                <h5>Estructura</h5>
                <p>
                  Esto quiere decir que las empresas logran estructurar cada uno
                  de los procesos del negocio para ser cada vez más eficientes.
                </p>
              </div>
            </div>
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">02</span>
              <div className="icon-main number-box">
                <span className="flaticon-document"></span>
              </div>
              <div className="content-box">
                <h5>Soporte</h5>
                <p>
                  Entregar un excelente soporte a cada una de las necesidades
                  del cliente, es muy importante y más por encontrarse en una
                  etapa de cambio.
                </p>
              </div>
            </div>
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">03</span>
              <div className="icon-main number-box">
                <span className="flaticon-search-1"></span>
              </div>
              <div className="content-box">
                <h5>Eficacia</h5>
                <p>
                  Un dato interesante es cómo cada vez, las empresas están
                  interesadas en mejorar la eficacia del resultado final de la
                  compañía.
                </p>
              </div>
            </div>
          </div>
          <div className="home7-feature-col2 align-self-center">
            <div className="feature-col-img">
              <img src={image2_home7} alt="" />
            </div>
          </div>
          <div className="home7-feature-col3">
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">04</span>
              <div className="icon-main number-box">
                <span className="flaticon-pie-chart"></span>
              </div>
              <div className="content-box">
                <h5>Centralizar</h5>
                <p>
                  El integrar todos los datos de ventas, inventario, compras y
                  otras áreas de la empresa ayuda a la toma de decisiones y a un
                  crecimiento más estructurado.
                </p>
              </div>
            </div>
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">05</span>
              <div className="icon-main number-box">
                <span className="flaticon-coding"></span>
              </div>
              <div className="content-box">
                <h5>Visibilidad</h5>
                <p>
                  Las pequeñas empresas requieren de tener una visibilidad
                  completa sobre el seguimiento, gestión, control y análisis de
                  los factores clave de negocio.
                </p>
              </div>
            </div>
            <div className="service-box s-box">
              <div className="overlay"></div>
              <span className="big-number">06</span>
              <div className="icon-main number-box">
                <span className="flaticon-cloud-computing"></span>
              </div>
              <div className="content-box">
                <h5>Decisión</h5>
                <p>
                  La toma de decisiones de manera inteligente y rápida, puede
                  ayudar a estar por delante de la competencia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParticleFeature;
