import { Link } from "react-router-dom";

import image1_home7 from "../assets/images/image1-home7.png";

const AboutUs = () => {
  return (
    <section className="hone7-about">
      <div className="container">
        <div className="flex-row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="hone7-about-img img-block">
              <img src={image1_home7} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 align-self-center">
            <div className="home7-about-block">
              <div className="ot-heading left-align">
                <h2 className="main-heading">
                  Mantener el control total de tus procesos con SAP Business One
                </h2>
              </div>
              <p className="font22 text-dark">
                SAP Business One te otorga un control administrativo integral y
                de gran alcance sobre tu negocio y tus subsidiarias.
              </p>
              <p className="m-b45">
                Esta solución de software flexible está diseñada para crecer
                junto con tu empresa, manteniendo el ritmo de tus necesidades en
                desarrollo. Más de 60000 empresas en 170 países de todo el mundo
                ya han optado por confiar en el soporte de SAP Business One para
                simplificar los procesos comerciales clave, desde la
                contabilidad hasta el CRM y la gestión de la cadena de
                suministro.
              </p>
              <div className="ot-button">
                <Link
                  to="/contactus"
                  className="octf-btn octf-btn-icon octf-btn-primary"
                >
                  Contáctenos<i className="flaticon-right-arrow-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
