import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";

const Footer = () => {
  return (
    <footer
      id="site-footer"
      className="site-footer bg-gradient footer-top-bottom"
    >
      <div className="footer-shape-top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          ></path>
        </svg>
      </div>
      <div className="container">
        <div className="footer-logo text-center">
          <img src={Logo} className="attachment-large size-large" alt="" />
        </div>

        <div className="flex-col">
          <div className="ft-col-contact text-center">
            <div className="contact-info box-style2 text-light">
              <div className="box-icon">
                <i className="flaticon-world"></i>
              </div>
              <p>Heriberto Covarrubias 21, 604, Ñuñoa</p>
              <h6>Nuestra dirección</h6>
            </div>
          </div>
          <div className="ft-col-contact text-center border-left border-right">
            <div className="contact-info box-style2 text-light">
              <div className="box-icon">
                <i className="flaticon-note"></i>
              </div>
              <p>
                <a href="mailto:contacto@siriusconsulting.cl">
                  contacto@siriusconsulting.cl
                </a>
              </p>
              <h6>Nuestro Mailbox</h6>
            </div>
          </div>
          <div className="ft-col-contact text-center">
            <div className="contact-info box-style2 text-light">
              <div className="box-icon">
                <i className="flaticon-viber"></i>
              </div>
              <p>
                <a href="tel:+56 22773435">+56 22773435</a>
              </p>
              <h6>Nuestro Teléfono</h6>
            </div>
          </div>
        </div>

        <div className="footer-menu">
          <ul id="menu-footer-menu" className="menu">
            <li>
              <Link to="/" aria-current="page">
                Inicio
              </Link>
            </li>
            <li>
              <Link to="/contactus">Contáctenos</Link>
            </li>
          </ul>
        </div>

        <p className="copyright text-center">
          © 2021 Sirius. Todos los derechos reservados.
        </p>

        {/*<div className="footer-social text-center">
          <a className="footer-social-icon twitter" href="#" target="_blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="footer-social-icon facebook" href="#" target="_blank">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a className="footer-social-icon linkedin" href="#" target="_blank">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a className="footer-social-icon instagram" href="#" target="_blank">
            <i className="fab fa-instagram"></i>
          </a>
        </div>*/}
      </div>
    </footer>
  );
};

export default Footer;
