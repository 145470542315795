import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";

const Header = () => {
  return (
    <header
      id="site-header"
      className="site-header header-style-2 sticky-header header-fullwidth"
    >
      {/* Main header start */}
      <div className="octf-main-header">
        <div className="octf-area-wrap">
          <div className="container-fluid octf-mainbar-container">
            <div className="octf-mainbar">
              <div className="octf-mainbar-row octf-row">
                <div className="octf-col">
                  <div id="site-logo" className="site-logo">
                    <Link to="/">
                      <img className="logo-static" src={Logo} alt="Sirius" />
                    </Link>
                  </div>
                </div>
                <div className="octf-col text-right">
                  {/* Call To Action */}
                  <div className="octf-btn-cta">
                    <div className="octf-header-module"></div>
                    <div className="octf-header-module">
                      <div className="btn-cta-group btn-cta-header">
                        <Link
                          className="octf-btn octf-btn-third"
                          to="/contactus"
                        >
                          Contáctenos
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main header close */}

      {/* Header mobile open */}
      <div className="header_mobile">
        <div className="container">
          <div className="mlogo_wrapper clearfix">
            <div className="mobile_logo">
              <Link to="/">
                <img src={Logo} alt="Sirius" data-lazy-src={Logo} />
                <noscript>
                  <img src={Logo} alt="Sirius" />
                </noscript>
              </Link>
            </div>
            <div id="mmenu_toggle">
              <button></button>
            </div>
          </div>
          <div className="mmenu_wrapper">
            <div className="mobile_nav">
              <ul id="menu-main-menu" className="mobile_mainmenu">
                <li>
                  <Link to="/contactus">Contáctenos</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header mobile close */}
    </header>
  );
};

export default Header;
