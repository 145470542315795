import {
  AboutUs,
  Home,
  Particle,
  ParticleFeature,
  Service,
} from "../components";

function Main() {
  return (
    <div id="content" className="site-content">
      <Home />
      <Service />
      <AboutUs />
      <Particle />
      <ParticleFeature />
    </div>
  );
}

export default Main;
